import React from "react";
import "../css/Body.css";
import { ReactComponent as UpIcon } from "../assets/upIcon.svg";
import { ReactComponent as DownIcon } from "../assets/downIcon.svg";

const Body = () => {
  const topGainers = [
    { code: "ABC", name: "Company ABC", price: "100.55 TRY", percentage: "+5.2%" },
    { code: "DEF", name: "Company DEF", price: "100.55 TRY",percentage: "+3.8%" },
    { code: "GHI", name: "Company GHI", price: "100.55 TRY",percentage: "+2.5%" },
    { code: "JKL", name: "Company JKL", price: "100.55 TRY",percentage: "+1.7%" },
    { code: "MNO", name: "Company MNO", price: "100.55 TRY",percentage: "+0.9%" },
    { code: "PQR", name: "Company PQR", price: "100.55 TRY",percentage: "+0.6%" },
    { code: "STU", name: "Company STU", price: "100.55 TRY",percentage: "+0.4%" },
    { code: "VWX", name: "Company VWX", price: "100.55 TRY",percentage: "+0.3%" },
    { code: "YZA", name: "Company YZA", price: "100.55 TRY",percentage: "+0.2%" },
    { code: "BCD", name: "Company BCD", price: "100.55 TRY",percentage: "+0.1%" },
  ];

  const topLosers = [
    { code: "XYZ", name: "Company XYZ", price: "100.55 TRY",percentage: "-3.8%" },
    { code: "123", name: "Company 123", price: "100.55 TRY",percentage: "-2.5%" },
    { code: "456", name: "Company 456", price: "100.55 TRY",percentage: "-1.7%" },
    { code: "789", name: "Company 789", price: "100.55 TRY",percentage: "-0.9%" },
    { code: "012", name: "Company 012", price: "100.55 TRY",percentage: "-0.6%" },
    { code: "345", name: "Company 345", price: "100.55 TRY",percentage: "-0.4%" },
    { code: "678", name: "Company 678", price: "100.55 TRY",percentage: "-0.3%" },
    { code: "901", name: "Company 901", price: "100.55 TRY",percentage: "-0.2%" },
    { code: "234", name: "Company 234", price: "100.55 TRY",percentage: "-0.1%" },
    { code: "567", name: "Company 567", price: "100.55 TRY",percentage: "-0.05%" },
  ];

  return (
    <div className="body-container">
      <div className="card-1">
        <h2 className="card-header-1">En Çok Yükselenler</h2>
        <div className="card-body-1">
          {topGainers.map((gainer, index) => (
            <div className="card-widget" key={index}>
              <div className="card-widget-name-container">
                <div className="card-widget-code">{gainer.code}</div>
                <div className="card-widget-name">{gainer.name}</div>
              </div>
              <div className="card-widget-percentage text-success">
                {gainer.percentage}
                <UpIcon />
              </div>
              <div className="card-widget-price-gainer">{gainer.price}</div>
            </div>
          ))}
        </div>
      </div>
      
      <div className="card-2">
        <h2 className="card-header-2">En Çok Düşenler</h2>
        <div className="card-body-2 ">
          {topLosers.map((loser, index) => (
            <div className="card-widget" key={index}>
              <div className="card-widget-name-container">
                <div className="card-widget-code">{loser.code}</div>
                <div className="card-widget-name">{loser.name}</div>
              </div>
              <div className="card-widget-percentage-2 text-error">
                {loser.percentage}
                <DownIcon />
              </div>
              <div className="card-widget-price-loser">{loser.price}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Body;
