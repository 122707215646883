import React from "react";
import "../css/Footer.css";
import Logo from "../assets/logo.png";
import SponsorLogo from "../assets/sponsor-logo.png";
import { RiInstagramFill, RiTwitterFill, RiYoutubeFill } from "react-icons/ri";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container text-center">
        <div className="logo-container">
          <img src={Logo} alt="Website Logo" className="website-logo" />
          <div className="footer-menu">
            <div className="menu-items">Günün Enleri</div>
            <div className="menu-items">Haftanın Enleri</div>
            <div className="menu-items">Ayın Enleri</div>
          </div>
          <img
            src={SponsorLogo}
            alt="Sponsor Logo"
            className="sponsor-logo-container"
          />
        </div>
        <div>
        
          <RiInstagramFill fontSize={30} cursor="pointer" />
          <RiTwitterFill
            fontSize={30}
            cursor="pointer"
            style={{ marginLeft: "30px", marginRight: "30px" }}
          />
          <RiYoutubeFill fontSize={30} cursor="pointer" />
        </div>
        <p className="text">
          &copy; {new Date().getFullYear()} borsakulesi.com. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
