import React,{useState} from "react";
import "../css/Header.css";
import Logo from "../assets/logo.png";
import { Fade as Hamburger } from "hamburger-react";

const Header = () => {
  const [toggle, settoggle] = useState(false)
  return (
    <header className="header">
      <div className="container">
        <Hamburger distance="md" size="28" color="#fff" onToggle={toggled => {
  if (toggled) {
     settoggle(true);

  } else {
     settoggle(false);
   
  }
}} /> 
        <a className="navbar-brand" href="/">
          Borsa Kulesi
          {/* <img src={Logo} alt="Logo" className="logo" /> */}
        </a>
        <button  className="btn">Kayıt Ol</button>
      </div>
      <div className={toggle ? 'hamburgerMenu active': 'hamburgerMenu'}>
        <ul>
          <li><a>Günün En Çok Yükselen ve Düşen Hisseleri</a></li>
          <li><a>Haftanın En Çok Yükselen ve Düşen Hisseleri</a></li>
          <li><a>Ayın En Çok Yükselen ve Düşen Hisseleri</a></li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
