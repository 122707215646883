import React from "react";
import Header from "../components/Header";
import Body from "../components/Body";
import NewsletterForm from "../components/NewsLetter";
import Footer from "../components/Footer";

const App = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <Body />
      <NewsletterForm />
      <Footer />
    </div>
  );
};

export default App;
